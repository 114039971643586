import React, { createContext, useReducer } from "react"
import { mapPointReducer } from "./map-point-reducer"

export const MapPointContext = createContext<[_: any, r: React.Reducer<any, any>]>([undefined, () => {}])

let previousY = 0 // to calcuclate intersection direction
export const getPreviousY = () => previousY
export const setPreviousY = (y: number) => (previousY = y)

const defaultState = { mapPoints: [], current: null, routes: [] }

export function withMapPoints(Comp: React.FC) {
  return (props: Record<string, any>) => {
    const mapRed = useReducer(mapPointReducer, defaultState)
    return (
      <MapPointContext.Provider value={mapRed}>
        <Comp {...props} />
      </MapPointContext.Provider>
    )
  }
}
