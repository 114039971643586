import styled from "styled-components"
import { animated } from "@react-spring/web"
import chroma from "chroma-js"

export const popupHeight = "400px"

interface PopupProps {
  expanded?: boolean
  style?: any
  children?: JSX.Element | JSX.Element[]
}

export const PopupWrapper = styled(animated.div)<PopupProps>`
  position: fixed;
  pointer-events: auto;

  top: calc(3rem + 2rem);
  right: 2rem;
  width: 380px;

  user-select: none;

  body.info-page & {
    opacity: 0 !important;
    pointer-events: none !important;
  }

  z-index: 20;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  color: white;
  font-family: "Roboto Condensed";
`

export const Title = styled.div`
  background-color: ${p => chroma(p.theme.bgDark).alpha(0.9).hex()};
  font-weight: 700;
  font-size: 1rem;
  line-height: 1;
  padding: 0.5em 1em;

  cursor: move;

  border-left: 3px solid ${(p) => p.color || p.theme.themeColor};

  display: flex;
  justify-content: space-between;
`

export const Body = styled.div`
  background-color: ${p => chroma(p.theme.bgDark).alpha(0.8).hex()};
  line-height: 1.3;
  max-height: ${popupHeight};
  overflow: auto;

  > * {
    max-width: 660px;
    margin: 0 auto;
    font-size: 1rem;
  }
  a,
  a:hover,
  a:visited,
  a:active {
    color: white;
    text-decoration: none;
    // border-bottom: 2px solid ${p => p.theme.themeColor};
    svg {
      font-size: 0.8em;
    }
  }
  a:hover {
    background-color: var(--hover-color);
  }
  > * :not(:last-child) {
    margin-bottom: 1em;
  }

  h4 {
    margin: 0 0.5em 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      padding: 0 0 0.1em 0;
      margin: 0;
    }
  }
  table {
    font-size: ${p => p.theme.fontSizeSuperSmall};
    td {
      vertical-align: top;
      word-break: break-word;
    }
    td:first-child {
      // text-transform: uppercase;
      width: 140px;
      padding-right: 1em;
    }
  }
  img {
    display: block;
    width: 100%;
  }
  > *:not(img) {
    margin: 0 1.5rem 1em;
    &:first-of-type {
      margin-top: 1rem;
    }
  }
`
