import { css } from "styled-components"

export const dropCapFirstLetterCss = css`
  float: left;
  font-size: ${(p) => p.theme.fontSizeH1};
  line-height: 0.65;
  margin: 0.18em 0.1em 0.1em 0;
`

export const dropCapCss = css`
  .intro-wrapper
    + p:not(.has-text-align-right):not(.has-small-font-size):first-letter,
  .meta-info + p:not(.no-drop-cap):first-letter,
   .special-relations + p:not(.no-drop-cap):first-letter, p.has-drop-cap:first-letter {
    ${dropCapFirstLetterCss}
  }
  :not(.translate-journalism) & {
    h4 + p:not(.no-drop-cap):first-letter {
      ${dropCapFirstLetterCss}
    }
  }
`
