import React, {
  createContext,
  useState,
  useMemo,
  useRef,
  useEffect,
} from "react"
import {
  BgChangerStyleWrapper,
  CreditWrapper,
  ImgWrapper,
  SecondaryWrapper,
} from "./bg-changer-wrapper.style"
import BgVideo from "../bg-video/bg-video"
import MuteButton from "../bg-video/mute-button"
import TvOverlay from "./tv-overlay"
import { Img, useIntersectionObserver } from "gatsby-source-dek-wp"
import { withVisComps } from "../scroll-trigger/vis-container"
import ReadingProgressBar from "../reading-progress-bar/reading-progress-bar"
import { useImagePreloader } from "./image-preloader"

interface BgChangerSetters {
  setVisuals: (_: any) => void
  setActive: (_: any) => void
}

export interface Visual {
  type: "image" | "video"
  src: string
  noCrop?: boolean
  noOverlay?: boolean
  noAudio?: boolean
  noAutoplay?: boolean
  poster?: string
  tracks?: React.ReactNode[]
  credit?: React.ReactNode
}

export const BgChangerContext = createContext<BgChangerSetters>({
  setVisuals: () => {},
  setActive: () => {},
})

export const VideoRefContext = createContext<any>(null)

interface BgChangerWrapperProps {
  children: React.ReactNode[]
  classList?: string[]
  isFirst?: boolean
  isLast?: boolean
}

const SecondaryWrapperWithVisComps = withVisComps(SecondaryWrapper)

export const BgChangerWrapper = ({
  children,
  classList = [],
  isFirst,
}: BgChangerWrapperProps) => {
  const [visuals, setVisuals] = useState<Visual[]>([])
  const [active, setActive] = useState<Visual | undefined>(undefined)
  const setters = useMemo(() => ({ setVisuals, setActive }), [])
  const [muted, setMuted] = useState(true)
  const [ref, inView] = useIntersectionObserver({
    rootMargin: "-40% 0px -20% 0px",
  })
  useImagePreloader(visuals, inView)
  const className = `bg-changer-wrapper ${active?.type || "inactive"} ${
    inView ? "in-view" : "not-in-view"
  } ${classList.join(" ")} ${active?.noCrop ? "figcaption-centered" : ""}`

  const [currVideoRef, setCurrVideoRef] = useState<HTMLVideoElement | null>(
    null,
  )
  const videoRef = useRef<HTMLVideoElement | null>(null)
  return (
    <>
      <VideoRefContext.Provider value={currVideoRef}>
        <BgChangerStyleWrapper className={className} ref={ref}>
          <MuteButton
            muted={muted}
            onClick={() => setMuted((m) => !m)}
            show={active && active.type === "video" && !active.noAudio}
          />
          <BgChangerContext.Provider value={setters}>
            <ImgWrapper>
              <figure>
                {visuals.map((v, i, vs) => {
                  const isActive = !!active ? v === active : i === 0
                  const className = `${isActive ? "active" : "inactive"} ${
                    v.noCrop ? "no-crop" : ""
                  }`
                  const isFirstVid =
                    v.type === "video" &&
                    !vs.slice(0, i).find((v2) => v2.type === "video")
                  return v.type === "image" ? (
                    <Img
                      key={i}
                      className={className}
                      src={v.src}
                      alt="background"
                      draggable="false"
                      loading="lazy"
                    />
                  ) : (
                    <BgVideo
                      key={i}
                      isFirst={isFirstVid}
                      src={v.src}
                      active={v === active}
                      noAutoplay={v.noAutoplay}
                      muted={muted}
                      tracks={v.tracks}
                      poster={v.poster}
                      setMuted={setMuted}
                      setCurrVideoRef={setCurrVideoRef}
                    />
                  )
                })}
                <TvOverlay
                  active={
                    !classList.includes("no-overlay") && !active?.noOverlay
                  }
                />
                <CreditWrapper>{active?.credit}</CreditWrapper>
              </figure>
            </ImgWrapper>
            <SecondaryWrapperWithVisComps>
              {children}
            </SecondaryWrapperWithVisComps>
          </BgChangerContext.Provider>
        </BgChangerStyleWrapper>
      </VideoRefContext.Provider>
      {isFirst && <ReadingProgressBar />}
    </>
  )
}
