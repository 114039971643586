import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import { LangContext } from "gatsby-source-dek-wp"
import { Map } from "mapbox-gl"

interface WrapperProps {
  hide: boolean
}

const LockButtonWrapper = styled.button<WrapperProps>`
  font-size: ${(p) => p.theme.fontSizeSmall} !important;
  position: absolute;
  z-index: 11;

  top: 5vh;
  right: ${(p) => p.theme.blockMarginLeftRight};
  @media screen and (max-width: ${(p) => p.theme.breakpointTablet}) {
    top: 5vh;
    right: ${(p) => p.theme.blockMarginLeftRightTablet};
  }
  @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
    bottom: 40px;
    right: 10px;
    top: auto;
  }

  transition: opacity 0.3s ease-in-out;
  opacity: ${(p) => (p.hide ? 0 : 1)};

  background-color: white;
  color: black;
  // border: 1px solid black;
  border-radius: 4px;
  pointer-events: ${(p) => (p.hide ? "none" : "auto")} !important;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  padding: 0.5em;
  svg {
    margin-left: 0.5em;
  }
  .dark-mode & {
    background-color: ${(p) => p.theme.bgDark};
    color: inherit;
    box-shadow: 2px 2px 10px rgba(255, 255, 255, 0.1);
  }
`

const LOCKED_TEXT: { [key: string]: string } = {
  de: "zur freien Kartenansicht",
  en: "unlock map",
}

const UNLOCKED_TEXT: { [key: string]: string } = {
  de: "zur Textansicht",
  en: "show text",
}

interface Props {
  isLocked: boolean
  onClick: () => void
  hide: boolean
}

const LockButton = ({ isLocked, onClick, hide }: Props) => {
  const lang = useContext(LangContext)
  return (
    <LockButtonWrapper onClick={onClick} hide={hide}>
      {isLocked
        ? LOCKED_TEXT[lang.id] || LOCKED_TEXT["en"]
        : UNLOCKED_TEXT[lang.id] || UNLOCKED_TEXT["en"]}
    </LockButtonWrapper>
  )
}

const useMapLock = (map: Map, inView: boolean) => {
  const [isLocked, setIsLocked] = useState(true)
  useEffect(() => {
    if (!map) return
    if (isLocked) {
      map.scrollZoom.disable()
      // if (isTouchDevice()) 
      map.dragPan.disable()
      map.dragRotate.disable()
      map.keyboard.disable()
      map.doubleClickZoom.disable()
    } else {
      map.scrollZoom.enable()
      // if (isTouchDevice()) 
      map.dragPan.enable()
      map.dragRotate.disable()
      map.keyboard.enable()
      map.doubleClickZoom.enable()
    }
  }, [map, isLocked])
  const btnComp = (
    <LockButton
      hide={!inView}
      isLocked={isLocked}
      onClick={() => setIsLocked((l) => !l)}
    />
  )
  return [isLocked, setIsLocked, btnComp] as const
}

export default useMapLock

function isTouchDevice() {
  return "ontouchstart" in window
}
