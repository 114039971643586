import React from "react"
import { Img, isElement } from "gatsby-source-dek-wp"
import innerText from "react-innertext"
import { COLORS } from "./routes"

export interface MapPoint {
  id: string
}

interface State {
  mapPoints: MapPoint[]
}

type Action =
  | {
      type: "add"
    }
  | { type: "remove " }
  | { type: "setCurrent" }
  | { type: "setRoutes" }

export const mapPointReducer: React.Reducer<State, Action> = (
  state,
  action,
) => {
  const { mapPoints } = state //
  const {
    type,
    id,
    lat,
    lon,
    category,
    onClick,
    routes,
    current,
    children,
    color = COLORS[0],
    hidden,
  } = action
  switch (type) {
    case "add":
      const h4 = (children || []).find((c) => c.type === "h4")
      const title = h4 ? innerText(h4) : ""
      const figure = (children || []).find((c) => c.type === "figure")
      const imgSrc = figure
        ? React.Children.toArray(figure.props.children).filter(isElement).find(
            (c) => c.type === "img" || c.type === Img,
          )?.props?.src
        : null
      return {
        ...state,
        mapPoints: [
          ...mapPoints,
          {
            id,
            lat,
            lon,
            category,
            onClick,
            children,
            title,
            imgSrc,
            color,
            hidden,
          },
        ],
      }
    case "remove":
      return {
        ...state,
        mapPoints: [...mapPoints.filter((a) => a.id !== id)],
      }
    case "setCurrent":
      return {
        ...state,
        current,
      }
    case "setRoutes":
      return {
        ...state,
        routes,
      }
    default:
      throw new Error()
  }
}

export function add(options) {
  const {
    id,
    lat,
    lon,
    category = "",
    onClick,
    children,
    color,
    hidden,
  } = options
  return {
    type: "add",
    id,
    lat,
    lon,
    category,
    onClick,
    children,
    color,
    hidden,
  }
}

export function remove(id) {
  return { type: "remove", id }
}

export function setCurrent(current) {
  return { type: "setCurrent", current }
}

export function setRoutes(routes) {
  return { type: "setRoutes", routes }
}
