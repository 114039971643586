const COMING_SOON_TAG_IDS = [83, 169, 173, 177, 181]

const DEFAULT_PLACEHOLDER = {
  de: "Dieses Material ist noch nicht veröffentlicht.",
  en: "This text is not published yet.",
  ru: "Русская версия будет опубликована позже.",
  uk: "This text is not published yet.",
  be: "This text is not published yet.",
}

function queryRes2Context(
  post,
  categoryNames = [],
  placeholder = DEFAULT_PLACEHOLDER,
  isAuthed = false,
  allPosts = [], // optional to filter translations
) {
  const langId = post.language.slug

  const {
    acfMeta,
    autoren,
    translators: _translators,
    sources: _sources,
    content = "",
    date,
    translations = [],
    socialImgOptions,
  } = post

  const filteredTranslations = !!allPosts.length
    ? translations.filter((t) =>
        allPosts.find((p) => p.databaseId === t.databaseId),
      )
    : translations

  const unifyPersonTaxonomyNode = ({ name, description = "", person }) => ({
    name,
    description,
    image: person?.image?.mediaItemUrl || "",
    url: person?.personUrl || "",
  })
  const authors = (autoren?.nodes || []).map(unifyPersonTaxonomyNode)
  const translators = (_translators?.nodes || []).map(unifyPersonTaxonomyNode)
  const sources = (_sources?.nodes || []).map(({ name, source }) => ({
    name,
    // description,
    color1: source?.color1 || "",
    color2: source?.color2 || "",
    url: source?.url || "",
    logo: source?.logo?.mediaItemUrl || "",
  }))
  // TODO: default values in graphql schema?
  const acfDefaults = {
    orderId: 0,
    slug: "",
    published: false,
    menuTitle: "",
    menuCategory: "",
    authorDescription: "",
    darkBackground: false,
    hasDiscussion: false,
    pageType: "",
    author: "",
    translator: "",
    photoEditor: "",
    dataVisualization: "",
    beginWithBody: false,
    themeColor: "",
    titleVideoSource: "",
    bodyClass: "",
    originalUrl: "",
    originalDate: "",
    shortened: false,
  }
  const filteredAcf = Object.fromEntries(
    Object.entries(acfMeta).filter(([_, v]) => v != null),
  )
  const tags = (post?.tags?.nodes || []).map((n) => n.databaseId)
  const categories = (post?.categories?.nodes || []).map(
    ({ databaseId: id, name }) => ({ id, name }),
  )
  const posterImgSizes =
    ((post.acfMeta.titleVideoPoster || {}).mediaDetails || {}).sizes || []
  const tileImgSizes =
    ((post.acfMeta.tileImage || {}).mediaDetails || {}).sizes || []
  // console.log(tileImgSizes)
  const titleVideoPoster = posterImgSizes.length
    ? (posterImgSizes.find((s) => s.name === "large") || {}).sourceUrl ||
      post.acfMeta.titleVideoPoster?.sourceUrl ||
      (posterImgSizes.find((s) => s.name === "medium_large") || {}).sourceUrl ||
      (posterImgSizes.find((s) => s.name === "medium") || {}).sourceUrl
    : ""
  const titleVideoPosterPreview = posterImgSizes.length
    ? (posterImgSizes.find((s) => s.name === "medium_large") || {}).sourceUrl ||
      (posterImgSizes.find((s) => s.name === "medium") || {}).sourceUrl
    : ""
  const tileImage = tileImgSizes.length
    ? (tileImgSizes.find((s) => s.name === "medium_large") || {}).sourceUrl ||
      (tileImgSizes.find((s) => s.name === "medium") || {}).sourceUrl
    : ""
  const slugs = (acfMeta.slug || "")
    .split(",")
    .map((s) => s.trim())
    .map((s) => {
      const splits = s.split(":")
      const hasCategory = splits.length > 1
      const _slug = hasCategory ? splits[1] : splits[0] || ""
      return {
        category: hasCategory ? splits[0] : "default",
        slug: _slug
          .trim()
          .replace(/^http(s)?:\/\//, "")
          .replace(/^\//, ""),
      }
    })

  const categorySpecificSlug = slugs.find((s) =>
    categoryNames.includes(s.category),
  )?.slug
  const defaultSlug = slugs[0].slug
  const slug =
    typeof categorySpecificSlug !== "undefined"
      ? categorySpecificSlug
      : defaultSlug
  if (typeof slug === "undefined") console.warn("Post without slug!", post)
  // console.log({ slug, categorySpecificSlug, defaultSlug, slugs, categoryNames })
  const context = {
    ...acfDefaults,
    ...filteredAcf,
    authors,
    translators,
    sources,
    slug,
    postId: post.databaseId,
    title: decodeHtmlEntitiesToUnicode(post.title),
    description: post.excerpt,
    content:
      !isAuthed && (COMING_SOON_TAG_IDS.includes(tags[0]) || !acfMeta.published)
        ? `<p class="not-published">${
            placeholder[langId] || DEFAULT_PLACEHOLDER["en"]
          }</p>`
        : content,
    comingSoon: COMING_SOON_TAG_IDS.includes(tags[0]),
    categories,
    image: {
      src: post.featuredImage?.node?.sourceUrl || "",
      width: 0,
      height: 0,
    },
    date,
    langId,
    translations: filteredTranslations.map((t) => ({ postId: t.databaseId })),
    authorImage: acfMeta.authorImage?.sourceUrl || "",
    titleVideo: acfMeta.titleVideo?.mediaItemUrl || "",
    titleVideoPoster,
    titleVideoPosterPreview,
    tileImage,
    socialImgOptions,
  }
  return context
}

/* function stripTags(string) {
  if (typeof string === "string") return string.replace(/(<([^>]+)>)/gi, "")
}*/

function decodeHtmlEntitiesToUnicode(str) {
  return str.replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec))
}

exports.queryRes2Context = queryRes2Context
