import { css } from "styled-components"
import { dropCapFirstLetterCss } from "../_dropcap.style"
import { TitleInner } from "../../cover/cover.style"

export const hlebaFontStyles = css`
  h1 {
    font-family: "HlebaSoliZiamliVoli";
    font-size: ${(p) => p.theme.fontSizeH1};
    text-transform: uppercase;
    @media screen and (min-width: ${(p) => p.theme.breakpointTablet}) {
      transform: translateX(-1em);
    }
  }
  ${TitleInner} h1 {
    transform: none !important;
  }
  ${TitleInner} h3 {
    font-size: ${(p) => p.theme.fontSizeH4} !important;
  }
  h2,
  h4 {
    font-family: "HlebaSoliZiamliVoli";
    text-transform: uppercase;
    font-size: ${(p) => p.theme.fontSizeH4};
    letter-spacing: 0.1em;
  }
  blockquote p {
    text-transform: uppercase;
    font-family: "HlebaSoliZiamliVoli";
    font-style: normal;
    font-size: ${(p) => p.theme.fontSizeH4};
    letter-spacing: 0.1em;
  }
  .wp-block-media-text p {
    &.has-large-font-size {
      font-family: "HlebaSoliZiamliVoli";
      letter-spacing: 0.05em;
      font-size: ${(p) => p.theme.fontSizeH3};
    }
    &.has-medium-font-size {
      font-family: "HlebaSoliZiamliVoli";
      letter-spacing: 0.05em;
      font-size: ${(p) => p.theme.fontSizeH5};
    }
  }
  hr.wp-block-separator.is-style-dots:after {
    font-family: "HlebaSoliZiamliVoli";
    font-size: ${(p) => p.theme.fontSizeH1};
    letter-spacing: 0;
    transform: translateY(25%);
  }
  .intro-wrapper p:not(.no-drop-cap):first-child:first-letter {
    ${dropCapFirstLetterCss}
  }

  hr.is-style-dots + p:not(.no-drop-cap):first-letter {
    ${dropCapFirstLetterCss}
  }
  .map-fill-layer-button {
    font-family: "HlebaSoliZiamliVoli";
    font-size: ${(p) => p.theme.fontSizeH5};
    letter-spacing: .04em;
    color: #222;
  }
`
