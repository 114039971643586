import React, { useRef, createContext, useState, useContext } from "react"
import { useTransformer } from "../../modules/transformer"
import { useInViewClass } from "../../modules/hooks"
import {
  usePreloadedPopups,
  withAnnotations,
  ExtraColumnWrapper,
} from "gatsby-plugin-popup-preloader"
import ReadingProgressBar from "../reading-progress-bar/reading-progress-bar"
import {
  withToc,
  withConsent,
  PageContext,
  withTermFreqTool,
  isElement,
} from "gatsby-source-dek-wp"
import { ArticleWrapper, ArticleBody, ArticleBodyInner } from "./article.style"
import { MetaInfo } from "../meta-info/meta-info"
import DonEnc from "donation-encourager"
import { ART_BODY_CLASS } from "../donation-encourager/donation-encourager" // useDonationEncourager, useDonEncPageView

import { NavBar } from "../nav-bar/nav-bar"

import { BgChangerWrapper } from "../bg-changer/bg-changer-wrapper"
import { CardDeck } from "../card-deck/card-deck"
import { ThreejsCanvasAsCover } from "../threejs-canvas/threejs-canvas-as-cover"

// TODO: dynamic?
import "./fonts/fonts.scss"
import { CoverAsTitle } from "../cover/cover-as-title"
import { Cover } from "../cover/cover"
import { Intro, AuthContext } from "gatsby-source-dek-wp"
import { ThemeProvider } from "styled-components"

export const ArticleRefContext = createContext<React.RefObject<HTMLDivElement> | undefined>(undefined)

interface PageInfo {
  hasIntro?: boolean
  hasCustomMeta?: boolean
}

export const PageInfoContext = createContext<PageInfo>({})

const Article = ({
  pageContext,
  selected,
  isMultiSubPage,
  isMultiMainPage,
  parentSlug,
  prev,
  next
}) => {
  const { titleVideo, titleVideoPoster } = pageContext
  const {
    content,
    popups,
    noDonationEncourager,
    pageType,
    description,
    bodyClass = "",
    categories = [],
  } = pageContext
  const bodyClassList = bodyClass.split(" ")
  const { beginWithBody, title } = selected
  usePreloadedPopups(popups)
  // const contentState = usePreview(content, postId, restApi)
  const contentState = content
  const transformedContent = useTransformer(contentState)
  const [ref] = useInViewClass("on-article-body", { threshold: 0 })
  const articleRef = useRef<HTMLDivElement>(null)
  const articleBodyRef = useRef<HTMLDivElement>(null)
  const innerClass = !noDonationEncourager ? ART_BODY_CLASS : ""
  // useDonationEncourager()
  // useDonEncPageView(transformedContent)
  const hasCover =
    (!!titleVideo || !!titleVideoPoster) &&
    !bodyClassList.includes("translate-journalism")
  const paddingClass = hasCover || beginWithBody ? "no-padding-top" : ""

  // TODO!
  const catsWithoutSubtitle = [
    34337, // Ukraine Unabhänngigkeit
    31753, // ukraine war faq
  ]
  const noSubtitle = categories.some(({ id }) =>
    catsWithoutSubtitle.includes(id)
  )
  const _transformedDescription =
    useTransformer(description)[0]?.props?.children
  const subtitle = !noSubtitle ? _transformedDescription : ""

  const [muted, setMuted] = useState(true)

  const noTitle =
  bodyClassList.includes("no-title") ||
    (Array.isArray(transformedContent) &&
    firstElIs(
      transformedContent,
      CoverAsTitle,
      BgChangerWrapper,
      CardDeck,
      ThreejsCanvasAsCover,
      "h1"
    ))
    
  const hasCustomMeta =
    Array.isArray(transformedContent) &&
    transformedContent.some((el) => el?.type === MetaInfo)

  const hasIntro =
    Array.isArray(transformedContent) &&
    !!transformedContent.find((el) => el?.type === Intro)

  const catClasses = categories.map(({ name }) => `cat-${name}`).join(" ")

  const theme = (currentTheme) => ({
    ...currentTheme,
    colors: {
      current: pageContext.themeColor,
    },
  })

  const isAuthed = useContext(AuthContext)

  return (
    <ThemeProvider theme={theme}>
      <ArticleRefContext.Provider value={articleBodyRef}>
        <PageContext.Provider value={pageContext}>
          <PageInfoContext.Provider value={{ hasIntro, hasCustomMeta }}>
            <ArticleWrapper
              ref={articleRef}
              className={`article-wrapper category-${pageType} ${bodyClass} ${catClasses} ${isMultiMainPage ? "multi-main-page" : ""} ${isAuthed && "is-authed"}`}
            >
              <ExtraColumnWrapper>
                <ArticleBody
                  className={`article-body category-${pageType} ${paddingClass} ${bodyClass}`}
                  ref={articleBodyRef}
                >
                  <ArticleBodyInner
                    className={`article-body-inner ${innerClass}`}
                    ref={ref}
                  >
                    {!noTitle && (
                      <>
                        <ReadingProgressBar
                          refEl={articleBodyRef.current || undefined}
                          id="text"
                        />
                        <h1 style={{ marginTop: "1.5em" }}>{title}</h1>
                        {!hasCustomMeta && <MetaInfo />}
                      </>
                    )}
                    {transformedContent}
                    {isMultiSubPage && (
                      <NavBar parent={parentSlug} prev={prev} next={next} />
                    )}
                    <DonEnc />
                  </ArticleBodyInner>
                </ArticleBody>
              </ExtraColumnWrapper>
            </ArticleWrapper>
          </PageInfoContext.Provider>
        </PageContext.Provider>
      </ArticleRefContext.Provider>
    </ThemeProvider>
  )
}

export default withTermFreqTool(withConsent(withToc(withAnnotations(Article))))

function firstElIs(elArray: JSX.Element[], ...compTypes: {}[]) {
  const firstEl = elArray.find((el) => typeof el === "object")
  return compTypes.includes(firstEl?.type)
}

function hasAsChild(children: React.ReactNode, ...compTypes: {}[]) {
  const flattenedChildren = flattenChildren(children).filter(isElement)
  console.log({ flattenedChildren })
  return compTypes.some((compType) =>
    flattenedChildren.some((child) => child?.type === compType))
}

type ReactChildArray = ReturnType<typeof React.Children.toArray>;

function flattenChildren(children: React.ReactNode): ReactChildArray {
  const childrenArray = React.Children.toArray(children);
  return childrenArray.reduce((flatChildren: ReactChildArray, child) => {
    if ((child as React.ReactElement<any>).type === React.Fragment) {
      return flatChildren.concat(
        flattenChildren((child as React.ReactElement<any>).props.children)
      );
    }
    return [...flatChildren, child];
  }, []);
}
