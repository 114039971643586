import styled from "styled-components"

export const RouteSelectorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 60vh;
  pointer-events: auto;
`

export const RouteButton = styled.button`
  padding: 1em;
  @media screen and (max-width: ${p => p.theme.breakpointMobile}) {
    padding: 0.5em;
  }
  img {
    width: 100px;
    height: 100px;
    @media screen and (max-width: ${p => p.theme.breakpointMobile}) {
      width: 70px;
      height: 70px;
    }
    object-fit: cover;
    border-radius: 50%;
    transition: 0.3s ease;
    // filter: grayscale(1);
    &:hover {
      filter: none;
    }
    padding: 3px;
    border: 3px solid ${(p) => p.color || "red"};
    margin-bottom: 0.5em;
  }
  > div {
    text-align: center;
    color: white;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
    // text-transform: uppercase;
    // letter-spacing: 1px;
    font-weight: 800;
  }
`
