import React, { createContext } from "react"
import { CardBack, CardDiv } from "./card.style"
import useFlip from "./flip"
import useDragMove from "./drag-move"
// import { isMobile } from "../../modules/utils"
import { Link } from "gatsby"
import { useContentFromPostId } from "../internal-teaser/internal-teaser"

export const MovingContext = createContext(false)
export const DragBindContext = createContext<(...args: any[]) => any>(() => {})

const exampleText = `Россия начала военную операцию по демилитаризации Украины рано утром в четверг, 24 февраля. В телеобращении к россиянам президент Владимир Путин заявил, что обстоятельства "требуют от нас решительных и незамедлительных действий, народные республики Донбасса обратились с просьбой о помощи".`

const Card = ({
  children,
  onSelect = () => {},
  initialRot,
  postId,
  onClick,
}) => {
  const [bind, style, set, moving] = useDragMove(onSelect, initialRot)
  const [flip, child, flipBtn] = useFlip(set, children) // flip

  const cardBind = bind() // isMobile() ? { onClick: onSelect } : bind()

  const content = useContentFromPostId(postId)

  if (!content) {
    // console.error("Content not found", { postId })
    // return null
  }

  const onLinkClick = (e) => {
    if (moving) {
      e.preventDefault()
      e.stopPropagation()
    } else if (typeof onClick === "function") {
      e.preventDefault()
      e.stopPropagation()
      onClick()
    } else if (!content) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  const hasBackText = child?.props?.backText

  return (
    <MovingContext.Provider value={moving}>
      <DragBindContext.Provider value={bind}>
        <CardDiv {...cardBind} style={style} onDoubleClick={flip}>
          <Link to={content ? `/${content?.slug}` : ""} onClick={onLinkClick}>
            {child}
          </Link>
          {hasBackText && flipBtn}
          {hasBackText && <CardBack><div>{child?.props?.backText}</div></CardBack>}
        </CardDiv>
      </DragBindContext.Provider>
    </MovingContext.Provider>
  )
}
export default Card
