import React, { useContext } from "react"
import { AllContentsContext, Tile } from "gatsby-source-dek-wp"

interface Props {
  postId: number
  title?: string
  kicker?: string
  fullscreen?: boolean
  bgImageUrl?: string
  bgImageUrlSmall?: string
  template?: string
  externalUrl?: string
  comingSoon?: boolean
  backText?: React.ReactNode
}

export const InternalTeaser = ({
  postId,
  title,
  kicker,
  fullscreen,
  bgImageUrl,
  bgImageUrlSmall,
  template,
  externalUrl,
  comingSoon
}: Props) => {
  const content = useContentFromPostId(postId)
  // if (!content && !externalUrl) return null
  return (
    <Tile
      content={content}
      title={title || content?.title}
      kicker={kicker}
      fullscreen={fullscreen}
      bgImageUrl={bgImageUrl}
      bgImageUrlSmall={bgImageUrlSmall}
      template={template}
      externalUrl={externalUrl}
      comingSoon={comingSoon}
    />
  )
}

export function useContentFromPostId(postId: number) {
  const contents = useContext(AllContentsContext)
  const content = contents.find((c) => c.postId === postId)
  return content
}
