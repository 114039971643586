import React, { Suspense } from "react"
const TimeMachine = React.lazy(() =>
  import("./time-machine").then((module) => ({
    default: module.TimeMachine,
  }))
)

export function timeMachineTransformer(node, i, { children, classList }) {
  if (classList.includes("wp-block-dekoder-custom-blocks-time-machine")) {
    return (
      <Suspense fallback={<div>loading ...</div>}>
        <TimeMachine />
      </Suspense>
    )
  }
}
