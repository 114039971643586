import { useMemo } from "react"

const NO_FORCE_CC_LANGS = ["be"]

export function useAutoCaptions(src: string, langId: string) {
  const patchedSrc = useMemo(() => {
    const url = new URL(src)
    url.host = "www.youtube-nocookie.com" // replace host 
    url.searchParams.append("hl", langId) // interface lang
    url.searchParams.append("cc_lang_pref", langId)
    if (!NO_FORCE_CC_LANGS.includes(langId)) {
      url.searchParams.append("cc_load_policy", "1")
    }
    url.searchParams.append("enablejsapi", "1")
    return url.href
  }, [src, langId])
  return patchedSrc
}