import React, { createContext, useState } from "react"

// TODO: merge with map point reducer ...

export const LayerContext = createContext([])

export function withLayerManager(Comp) {
  return (props) => {
    const layerState = useState([])
    return (
      <LayerContext.Provider value={layerState}>
        <Comp {...props} />
      </LayerContext.Provider>
    )
  }
}
