// const path = require("path")

// ids for special category in WP
exports.categoryIds = [
  285, // DE
  289, // EN
  295, // RU
  27091, // BE
]

// new: category names (allows to fetch posts in child categories)
exports.categoryNames = process.env.GATSBY_CATEGORY_NAMES
  ? process.env.GATSBY_CATEGORY_NAMES.split(",").map((s) => s.trim())
  : ["specials-de", "specials-en", "specials-ru", "specials-be"]

exports.metaInfos = [
  {
    langId: "de",
    title: "dekoder-Specials",
    description: "„Eine Spielwiese für Netzformate“ – unsere dekoder-Specials",
    defaultSocialImg:
      "https://specials.dekoder.org/content/uploads/2020/02/specials_social1.png",
  },
  {
    langId: "en",
    title: "dekoder Specials",
    description: "",
    defaultSocialImg:
      "https://specials.dekoder.org/content/uploads/2020/02/specials_social1.png",
  },
  {
    langId: "ru",
    title: "Спецпроекты dekoder.org",
    description: "",
    defaultSocialImg:
      "https://specials.dekoder.org/content/uploads/2020/02/specials_social1.png",
  },
  {
    langId: "uk",
    title: "Спецпроекти dekoder.org",
    description: "",
    defaultSocialImg:
      "https://specials.dekoder.org/content/uploads/2020/02/specials_social1.png",
  },
  {
    langId: "be",
    title: "Спецпраекты dekoder.org",
    description: "",
    defaultSocialImg:
      "https://specials.dekoder.org/content/uploads/2020/02/specials_social1.png",
  },
]

exports.socialImgComp = require.resolve(
  "./src/components/social-image/social-image.js",
)

exports.siteMetadata = {
  title: `dekoder-Specials`,
  description: ``,
  siteUrl: `https://specials.dekoder.org`,
  author: `dekoder.org`,
}

exports.pathPrefix = ""
exports.faviconPath = `src/images/favicon.png`
exports.layoutComponentPath = require.resolve("./src/components/layout")
exports.graphqlApi = `https://wp.dekoder.org/wp/graphql`
exports.restApi = `https://wp.dekoder.org/wp-json/wp/v2/posts` // `http://localhost:8080/wp-json/wp/v2/posts` //

exports.customHook = (createPage) => {
  createPage({
    path: `/_iframe`,
    component: require.resolve(`./src/components/_iframe.tsx`),
    context: {
      layout: "no-layout",
    },
  })
}
