import { useState } from "react"
import { useSpring, to } from "@react-spring/web"
import { useDrag } from "@use-gesture/react"

const randomRot = () => -10 + Math.random() * 20
const trans = (r: number, s: number, yRot: number) => `rotateY(${yRot}deg) rotateZ(${r}deg) scale(${s})`

export default function useDragMove(onSelect: () => void, initialRot: number) {
  const [moving, setMoving] = useState(false)
  const defaultScale = 1
  const [props, set] = useSpring(() => {
    const rot = typeof initialRot === "number" ? initialRot : randomRot()
    return { x: 0, y: 0, rot, scale: defaultScale, yRot: 0 }
  })

  const bind = useDrag(
    ({ down, offset: [x, y] }) => {
      onSelect()
      const rot = down ? 0 : randomRot()
      const scale = down ? defaultScale * 1.1 : defaultScale
      const config = { friction: 50, tension: down ? 800 : 500 }
      set({ x, y, rot, scale, config })
      if (down) setMoving(true)
      else setTimeout(() => setMoving(false), 100)
    },
    { delay: true, drag: { filterTaps: true } }
  )

  const { x, y, rot, scale, yRot } = props
  const transform = to([rot, scale, yRot], trans)

  const style = { x, y, transform }
  return [bind, style, set, moving] as const
}
