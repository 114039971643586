import React from "react"

const Page = (props) => {
  return (
    <iframe
      style={{
        width: "100%",
        height: "100vh"
      }}
      src={`https://specials.dekoder.org/v-seti-propagandy/${props.location.search}`}
      frameBorder="0"
    ></iframe>
  )
}

export default Page
