import React from "react"
import styled from "styled-components"
import { useContext, useEffect, useState } from "react"
import { ClipboardBtn } from "./clipboard-btn"
import { MapContext } from "./map"
import { AuthContext } from "gatsby-source-dek-wp"

const Wrapper = styled.div`
  position: absolute;
  right: 10px;
  bottom: 30px;
  font-family: "Roboto Condensed";
  font-size: 0.7em;
  text-align: right;
  z-index: 50;

  @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
    bottom: auto;
    top: 10px;
  }
`

export const CameraDebug = () => {
  const [, setCounter] = useState(0)
  const map = useContext(MapContext)
  useEffect(() => {
    if (!map) return
    const update = () => setCounter((c) => c + 1)
    map.on("move", update)
    return () => {
      map.off("move", update)
    }
  }, [map])
  const isAuthed = useContext(AuthContext)
  if (!map || !isAuthed) return null
  const { lng, lat } = map.getCenter()
  const center = [round(lng), round(lat)]
  const zoom = round(map.getZoom(), 1)
  const bearing = Math.round(map.getBearing())
  const pitch = Math.round(map.getPitch())
  const cameraOpts = { center, zoom, bearing, pitch }
  return (
    <Wrapper>
      <div>
        {JSON.stringify(cameraOpts)}{" "}
        <ClipboardBtn text={JSON.stringify(cameraOpts)} />
      </div>
    </Wrapper>
  )
}

function round(x: number, decimalPlaces = 5) {
  return Math.round(x * 10 ** decimalPlaces) / 10 ** decimalPlaces
}
