import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react"
import { BoxWrapper, MapPointWrapper } from "../map/map-point.style"
import { useIntersectionObserver } from "gatsby-source-dek-wp"
import { throttle } from "throttle-debounce"
import { SCROLL_OFFSET_TOP } from "../map/map-point"
import { MapContext } from "../map/map"
import length from "@turf/length"
import along from "@turf/along"
import { lineString } from "@turf/helpers"
import { VisRefContext } from "./vis-container"
import chroma from "chroma-js"
import { VideoRefContext } from "../bg-changer/bg-changer-wrapper"
import { ScrollDownButton } from "./scroll-down-button"
import { getOffset } from "../map/fly-to"
import mapboxgl from "mapbox-gl"

const SCROLL_THROTTLE = 10
// const SCROLL_OFFSET_TOP = 50

interface TriggerSetting {
  // extends CameraOptions
  center: [number, number]
  bearing: number
  zoom: number
  pitch: number
}

interface ScrollTriggerProps {
  children: JSX.Element[]
  styleChildren?: JSX.Element[]
  triggerSettings: TriggerSetting[]
  isFirst: boolean
  isLast: boolean
  id: string
  script?: JSX.Element
}

export const ScrollTrigger: React.FC<ScrollTriggerProps> = ({
  children,
  styleChildren,
  triggerSettings,
  isFirst,
  isLast,
  id,
  script,
}) => {
  const [ref, inView] = useExternalCallbacks(id, isLast)
  useCameraScroll(triggerSettings, ref, inView, isFirst, isLast)
  return (
    <MapPointWrapper
      ref={ref}
      className="scroll-trigger"
      // style={{ border: `1px dashed ${inView ? "green" : "yellow"}` }}
    >
      {children.map((c, i) => {
        const isFirst = i === 0
        const isScreenCenter = (c?.props?.className || "").includes("pt-50")
        return (
          <BoxWrapperWithInViewTrigger
            // style={i === 0 ? { paddingTop: "50vh" } : undefined}
            script={c?.props?.script}
            id={c?.props?.id}
            key={i}
            className={`${c?.props?.className}`}
          >
            {c}
            {isFirst && isScreenCenter && <ScrollDownButton />}
          </BoxWrapperWithInViewTrigger>
        )
      })}
      {!!script && script}
      {styleChildren}
    </MapPointWrapper>
  )
}

export function offsetCenter(
  map: mapboxgl.Map,
  center: mapboxgl.LngLat | [number, number],
  offset: [number, number],
  remove?: boolean,
) {
  const modifier = remove ? 1 : -1
  const _offset = offset.map((o) => o * modifier)
  const newCenterPx = map
    .project(center)
    .add(new mapboxgl.Point(_offset[0], _offset[1]))
  const newCenter = map.unproject(newCenterPx)
  return newCenter
}

function useCameraScroll(
  triggerSettings: TriggerSetting[],
  ref: React.RefObject<HTMLElement | null>,
  inView: boolean,
  isFirst: boolean,
  isLast: boolean,
) {
  const map = useContext(MapContext)
  const hasTriggerSettings = triggerSettings?.length > 0
  const line = useMemo(
    () =>
      hasTriggerSettings
        ? lineString(triggerSettings.map(({ center }) => center))
        : null,
    [triggerSettings],
  )
  const distance = useMemo(() => !!line && length(line), [line])

  const scrollCallback = useCallback(
    (percent: number) => {
      if (!map) return // interpolate camera settings between given first and last
      if (!line || !distance) return
      const currPos = along(line, distance * percent).geometry.coordinates
      const first = triggerSettings[0]
      const last = triggerSettings[1]
      const center = offsetCenter(map, currPos as [number, number], getOffset())
      const options = {
        zoom: interpolate(first.zoom, last.zoom, percent),
        pitch: interpolate(first.pitch, last.pitch, percent),
        bearing: interpolate(first.bearing, last.bearing, percent),
        center,
      }
      map.jumpTo(options)
    },
    [map, line, distance, triggerSettings],
  )

  useEffect(() => {
    if (!isFirst || !map || !hasTriggerSettings) return
    scrollCallback(0)
  }, [map, isFirst, hasTriggerSettings])

  useEffect(() => {
    if (!inView || !hasTriggerSettings) return
    const onScroll = () => {
      if (!ref.current) return

      const container = ref.current // ?.offsetParent // ?.offsetParent
      const percent = calcScrollPercent(container, {
        yOffset: (SCROLL_OFFSET_TOP / 100) * window.innerHeight,
        maxScrollOffset: isFirst
          ? 0
          : -(SCROLL_OFFSET_TOP / 100) * window.innerHeight, // 0,
      })
      scrollCallback(percent)
    }
    const throttledOnScroll = throttle(
      SCROLL_THROTTLE,
      onScroll,
    ) as EventListener
    window.addEventListener("scroll", throttledOnScroll)
    return () => {
      window.removeEventListener("scroll", throttledOnScroll)
    }
  }, [scrollCallback, ref, isFirst, inView, hasTriggerSettings])
}

function interpolate(first: number, last: number, percent: number, digits = 2) {
  const val = first + (last - first) * percent
  return val
  // return parseFloat(val.toFixed(digits))
}

declare global {
  interface Window {
    [key: string]: any
  }
}

export const OBSERVER_OPTIONS: Record<string, any> = {
  threshold: 0,
  rootMargin: `-${SCROLL_OFFSET_TOP}% 0% -${100 - SCROLL_OFFSET_TOP}% 0%`,
}

export function useExternalCallbacks(
  id: string,
  isLast?: boolean,
  observerOptions = OBSERVER_OPTIONS,
) {
  // const SCROLL_OFFSET_TOP = 100

  const [ref, inView, direction] = useIntersectionObserver(observerOptions)
  const wasInView = useRef(false)

  const map = useContext(MapContext)
  const refs = useContext(VisRefContext)
  const videoRef = useContext(VideoRefContext)
  const _callbackProps = useMemo(() => {
    return {
      map,
      container: refs?.container?.current,
      box: refs?.box?.current,
      boxLeft: refs?.boxLeft?.current,
      video: videoRef?.current,
      chroma,
    }
  }, [
    map,
    refs?.container?.current,
    refs?.box?.current,
    refs?.boxLeft?.current,
    videoRef?.current,
  ])

  const onInit = `onInit_${id}`
  const onEnter = `onEnter_${id}`
  const onLeave = `onLeave_${id}`
  const onScroll = `onScroll_${id}`

  // onInit effect
  useEffect(() => {
    if (typeof window[onInit] === "function") {
      const { box, boxLeft, container } = _callbackProps
      if (refs?.box && !box) return
      if (refs?.boxLeft && !boxLeft) return
      if (refs?.container && !container) return
      window[onInit](_callbackProps)
    }
  }, [_callbackProps])

  // onEnter / onLeave / onScroll effect
  useEffect(() => {
    if (!id) return
    const callbackProps = {
      ..._callbackProps,
      direction,
    }

    if (inView && !wasInView.current) {
      wasInView.current = true
    }

    try {
      if (
        !inView &&
        wasInView.current &&
        typeof window[onLeave] === "function"
      ) {
        window[onLeave](callbackProps)
      }

      if (inView && typeof window[onEnter] === "function") {
        // make sure onEnter is triggered after last onLeave when scrolling up
        const timeout = direction === "up" ? 10 : 0
        setTimeout(() => window[onEnter](callbackProps), timeout)
      }

      if (inView && typeof window[onScroll] === "function") {
        const cb = () => {
          if (!ref.current) return
          const container = ref.current // ?.offsetParent // ?.offsetParent
          const percent = calcScrollPercent(container, {
            yOffset: (SCROLL_OFFSET_TOP / 100) * window.innerHeight,
            maxScrollOffset: isLast
              ? -((SCROLL_OFFSET_TOP / 100) * window.innerHeight)
              : 0,
          })
          window[onScroll]({ ...callbackProps, percent })
        }
        const throttledCb = throttle(SCROLL_THROTTLE, cb) as EventListener
        window.addEventListener("scroll", throttledCb)
        return () => window.removeEventListener("scroll", throttledCb)
      }
    } catch (e) {
      console.error(e)
    }
  }, [inView, id, _callbackProps, wasInView, direction, isLast])

  return [ref, inView] as const
}

interface BoxWrapperProps {
  id: string
  script?: React.ReactNode
  children?: React.ReactNode
  className?: string
  [key: string]: any
}

export function BoxWrapperWithInViewTrigger(props: BoxWrapperProps) {
  const { id, script, children, className, ...otherProps } = props
  const [ref, inView] = useExternalCallbacks(id)
  return (
    <BoxWrapper
      ref={ref}
      className={`${!!className && className} ${inView ? "in-view" : ""}`}
      {...otherProps}
    >
      {children}
      {!!script && script}
    </BoxWrapper>
  )
}

interface CalcScrollPercentOptions {
  maxScrollOffset?: number
  yOffset?: number
}

function calcScrollPercent(
  container: HTMLElement,
  options: CalcScrollPercentOptions, // true
) {
  const { maxScrollOffset = 0, yOffset = 0 } = options
  const rect = container.getBoundingClientRect()
  const containerHeight = container.offsetHeight
  const maxScroll = containerHeight + maxScrollOffset // - 2 * HEADER_HEIGHT
  const currScroll = -rect.y + yOffset // + window.innerHeight

  const percent = Math.min(Math.max(currScroll / maxScroll, 0), 1)
  return percent
}
