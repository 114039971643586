import React, { useContext, useRef } from "react"
import { transformString } from "../../modules/transformer"
import { getFormatedDate } from "../../modules/utils"

import {
  LangContext,
  PageContext,
  Source,
  SpecialRelations,
} from "gatsby-source-dek-wp"
import { MetaInfoWrapper } from "./meta-info.style"
import { usePersons } from "./persons"

import { PageInfoContext } from "../article/article"
import { Content } from "gatsby-source-dek-wp/hooks/contents"

/* const videoSourcePrefix = { 
  de: "Video", 
  en: "Video", 
  ru: "Видео", 
  ua: "Відео" 
}*/

const originalPrefix = {
  de: "Original",
  en: "Original",
  ru: "Оригинал",
  uk: "Оригінал",
  be: "Арыгінал",
}

const titleVideoSourcePrefix = ({ hasVideo, langId }) => {
  const imageSourcePrefix = {
    de: "Titelbild",
    en: "Photo",
    ru: "Фотография",
    uk: "фотографія",
    be: "Фатаграфія",
  }
  const videoSourcePrefix = {
    de: "Video",
    en: "Video",
    ru: "Видео",
    uk: "Відео",
    be: "Відэа",
  }
  return hasVideo
    ? `${videoSourcePrefix[langId]}: `
    : `${imageSourcePrefix[langId]}: `
}
const videoCutPrefix = {
  de: "Schnitt",
  en: "Video Editing",
  ru: "Монтаж",
  uk: "Монтаж",
  be: "Мантаж",
}
const photoEditorPrefix = {
  de: "Bildredaktion",
  en: "Photo Editing",
  ru: "Бильдредактор",
  uk: "Більдредактор",
  be: "Більдрэдактар",
}

const authorPrefix = {
  de: "Text",
  en: "Text",
  ru: "Текст",
  uk: "Текст",
  be: "Тэкст",
}

const translatorPrefix = ({ langId, isShortened }) => {
  const transl = {
    de: "Übersetzung",
    en: "Translation",
    ru: "Перевод",
    uk: "Переклад",
    be: "Пераклад",
  }
  const shortened = {
    de: "gekürzt",
    en: "shortened",
    ru: "сокращенная версия",
    uk: "скорочена версія",
    be: "скарочаная версія",
  }
  return `${transl[langId]}${isShortened ? ` (${shortened[langId]})` : ""}`
}
const dataVisualizationPrefix = {
  de: "Datenvisualisierung",
  en: "Data visualisation",
  ru: "Визуализация данных",
  uk: "Візуалізація даних",
  be: "Візуалізацыя дадзеных",
}

interface MetaField {
  propName: string
  prefix?: string | { [_: string]: string } | ((_: any) => string)
  transform?: (_: any) => string
}

const metaFields: MetaField[] = [
  // { propName: "author", prefix: authorPrefix,  },
  // { propName: "translator", prefix: translatorPrefix },
  { propName: "titleVideoSource", prefix: titleVideoSourcePrefix },
  { propName: "titleVideoCut", prefix: videoCutPrefix },
  { propName: "photoEditor", prefix: photoEditorPrefix },
  { propName: "dataVisualization", prefix: dataVisualizationPrefix },
  { propName: "date", transform: getFormatedDate },
]

interface Props {
  type?: string
  titleVideoSource?: string | React.ReactNode
  withSpecialRelations?: boolean
}

export const MetaInfo = ({ type = "", titleVideoSource, withSpecialRelations }: Props) => {
  const pageContext: Content = useContext(PageContext) || {}

  const { hasIntro } = useContext(PageInfoContext)
  const {
    authorImage, // from acfMeta – deprecated
    author, // from acfMeta – deprecated
    authorDescription, // from acfMeta – deprecated
    date,
    shortened,
    titleVideo,
    authors: _authors,
    translators: _translators,
    translator, // from acfMeta – deprecated
    originalUrl,
    originalDate,
  } = pageContext

  const data = titleVideoSource
    ? { ...pageContext, titleVideoSource }
    : pageContext
  // use authors & translators from taxonomy OR (deprecated) from acfMeta
  const authors = _authors?.length
    ? _authors
    : author
    ? [{ name: author, description: authorDescription, image: authorImage }]
    : []
  const authorsWithImg = authors.filter((a) => !!a.image)
  const translators = _translators?.length
    ? _translators
    : translator
    ? [{ name: translator, description: "", image: "" }]
    : []
  const hasVideo = !!titleVideo
  const isShortened = shortened === true
  const metaRef = useRef<HTMLDivElement>(null)
  const langId = useContext(LangContext).id
  const [authorNames, authorPopup, authorImgs] = usePersons(
    authors,
    authorPrefix[langId],
    metaRef,
  )
  const [translatorNames, translatorPopup] = usePersons(
    translators,
    translatorPrefix({ langId, isShortened }),
    metaRef,
  )
  const metaItems = metaFields
    .filter((f) => !!data[f.propName])
    .map((f, i) => {
      const prefix =
        typeof f.prefix === "function"
          ? f.prefix({ isShortened, langId, hasVideo })
          : typeof f.prefix === "object"
          ? `${f.prefix[langId]}: `
          : f.prefix || ""
      return (
        <span className="meta-item" key={i}>
          {prefix}
          {typeof f.transform === "function"
            ? f.transform(data[f.propName])
            : typeof data[f.propName] === "string"
            ? transformString(data[f.propName])
            : data[f.propName]}
        </span>
      )
    })
  const originalLink = !!originalUrl && (
    <span className="meta-item">
      <a href={originalUrl} target="_blank" rel="noreferrer">
        Original
      </a>
      {false && !!originalDate && ` ${originalDate}`}
    </span>
  )

  return (
    <>
      <MetaInfoWrapper
        className={`meta-info ${type || ""}${
          !author && !date && !metaItems.length ? " no-items" : ""
        }`}
        ref={metaRef}
      >
        {authorsWithImg.length <= 2 && authorImgs}
        <div className={`meta-items`}>
          {authorNames}
          {originalLink}
          {translatorNames}
          {metaItems}
        </div>
        {authorPopup}
        {translatorPopup}
      </MetaInfoWrapper>
      {!hasIntro && <Source />}
      {(!hasIntro || withSpecialRelations) && <SpecialRelations />}
    </>
  )
}
