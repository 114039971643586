import styled from "styled-components"
import { dropCapCss } from "./_dropcap.style"
import { specialStyles } from "./special-styles.style"
import { figcaptionStyles } from "./_figcaption.style"
import { IntroWrapper } from "gatsby-source-dek-wp"

export const ArticleWrapper = styled.div`
  background: white;
  color: black;
  ${specialStyles}
`

export const ArticleBody = styled.div`
  position: relative;
  padding: 0;
  // padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  &.no-padding-top {
    padding-top: 0;
  }

  > p:first-child {
    margin-top: 2em;
  }

  p {
    font-family: "Lora", serif;
    line-height: 1.5;
    &.has-small-font-size {
      font-size: ${(p) => p.theme.fontSizeSmall};
    }
  }

  p.split-words {
    mark span {
      color: black;
      background-color: var(--mark-color);
      white-space: nowrap;
      box-shadow:
        0.275em 0 var(--mark-color),
        -0.275em 0 var(--mark-color),
        10px 5px #1E1E22;
      &:not(:last-child) {
        margin-right: 0.4em;
      }
    }
  }

  .has-text-align-right {
    text-align: right;
  }

  .font-roboto-condensed {
    font-family: "Roboto Condensed", sans-serif;
  }

  sup {
    line-height: 1;
    font-size: ${(p) => p.theme.fontSizeSuperSmall};
  }

  .figcaption {
    ${figcaptionStyles};
  }

  ${dropCapCss}
`

interface InnerProps {
  ref: React.RefObject<HTMLDivElement>
}

export const ArticleBodyInner = styled.div<InnerProps>`
  min-height: 90vh;
  // don't put "overflow: hidden" here – will break sticky contents

  h1 {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: ${(p) => p.theme.fontSizeH2};
  }
  h2,
  h3 {
    margin-top: 2em !important;
    // margin-bottom: 2em !important;
  }

  h4:not(.subheading) {
    margin-top: 3em !important;
    margin-bottom: 2em !important;
    text-transform: uppercase;
    &.has-text-align-center {
      text-align: center;
      margin-top: 3em !important;
      margin-bottom: 3em !important;
      text-transform: none;
    }
  }

  & > * {
    max-width: ${(p) => p.theme.blockMaxWidth};
    margin: 0 ${(p) => p.theme.blockMarginLeftRight}
      ${(p) => p.theme.blockMarginBottom};

    .article-wrapper.category-centered &,
    .title-centered,
    &.block-align-center {
      max-width: ${(p) => p.theme.blockMaxWidthCentered};
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (max-width: ${(p) => p.theme.breakpointTablet}) {
      max-width: ${(p) => p.theme.blockMaxWidthTablet};
      margin-left: ${(p) => p.theme.blockMarginLeftRightTablet};
      margin-right: ${(p) => p.theme.blockMarginLeftRightTablet};

      .article-wrapper.category-centered & {
        max-width: ${(p) => p.theme.blockMaxWidthCenteredTablet};
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      padding: 0 15px;
      margin: 0;
      margin-bottom: ${(p) => p.theme.blockMarginBottom};
      max-width: none !important;
    }

    @media print {
      padding: 0 15px ${(p) => p.theme.blockMarginBottom};
      margin: 0;
      max-width: none !important;
    }
  }

  .title-centered & {
    & h1 {
      // & ${IntroWrapper}, & .ph-social-bar {
      text-align: center;
      @media screen and (min-width: ${(p) => p.theme.breakpointMobile}) {
        margin-left: auto;
        margin-right: auto;
        max-width: ${(p) => p.theme.blockMaxWidthCentered};
      }
    }
  }

  .has-text-align-center {
    text-align: center;
  }

  > ul {
    font-family: "Lora", serif;
    line-height: 1.5;
    list-style: none;
    padding-inline-start: 0;
    padding-left: 1.5em;
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      padding: 0 ${(p) => p.theme.sidePaddingMobile};
    }
    li {
      margin: 0.5em 0;
      display: flex;
      justify-items: flex-start;
      &::before {
        display: block;
        padding-right: 0.5em;
        content: ">>";
        font-weight: 700;
        color: ${(p) => p.theme.gnoseBlue};
      }
    }
  }

  figure.alignleft {
    float: left;
    margin: 0.4rem 2rem 1rem 0;
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      float: none;
      margin: 0.4rem 1rem 1rem 15px;
    }
  }

  .flex-wrapper {
    max-width: 1400px !important;
    // border: 1px dashed red;
  }
  .flex-wrapper,
  .flex-wrapper-medium {
    margin: 2.5rem auto !important;
    > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      > a {
        border: none !important;
      }
    }
  }
  .wp-block-group.is-layout-flex {
    max-width: 1400px !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  > .bg-changer-wrapper:first-child {
    margin-top: -5px !important; // otherwise sticky reading-progress-bar would cause gap
  }

  > blockquote,
  .wp-block-pullquote > blockquote {
    font-style: italic;
    margin-top: 3em !important;
    margin-bottom: 3em !important;
    font-family: "Lora", serif;

    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      margin-top: 2em;
      margin-bottom: 2em;
    }

    p {
      font-size: ${(p) => p.theme.fontSizeH5};
      font-weight: 700;
      // line-height: 1.8;
      margin-left: -2em;
      font-style: italic;
      em {
        font-style: normal;
      }
      @media screen and (max-width: ${(p) => p.theme.breakpointTablet}) {
        margin-left: 0;
      }
      @media print {
        margin-left: 0;
      }
      &:before {
        font-size: ${(p) => p.theme.fontSizeH2};
        line-height: 0.1;
        content: "„";
        position: relative;
        display: inline-block;
        left: 0px;
        width: 50px;
        color: ${(p) => p.theme.titleColorSolid};

        @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
          top: -10px;
        }
      }
      &:not(:first-child):before {
        display: none;
      }
    }
    li {
      margin-bottom: 0.5em;
    }
  }
  figure.wp-block-pullquote {
    blockquote {
      margin-left: 0;
      margin-right: 0;
      p {
        margin-left: 0;
      }
    }
  }

  .wp-block-ugb-container {
    background-color: transparent !important;
  }

  > .wp-block-image,
  > .imp-initialized,
  > .wp-block-embed {
    // margin: 0;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;
    @media screen {
      padding: 0;
    }

    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      margin: 1em 0;
      padding: 0 0 0 !important;
    }
  }

  .wp-block-image,
  .imp-initialized {
    figure.aligncenter {
      margin-left: auto;
      margin-right: auto;
    }

    figure.size-medium {
      max-width: 400px;
    }

    &.vg-wort {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      height: 1px !important;
      img {
        width: 1px !important;
        height: 1px !important;
      }
    }

    &.fullsize {
      @media screen and (min-width: ${(p) => p.theme.breakpointMobile}) {
        max-width: none !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }

    &.bigsize {
      @media screen and (min-width: ${(p) => p.theme.breakpointMobile}) {
        max-width: calc(
          100% - 2 * ${(p) => p.theme.blockMarginLeftRightTablet}
        ) !important;
        margin-left: ${(p) => p.theme.blockMarginLeftRightTablet};
        margin-right: ${(p) => p.theme.blockMarginLeftRightTablet};
      }
      @media screen and (min-width: ${(p) => p.theme.breakpointTablet}) {
        max-width: calc(
          100% - 2 * ${(p) => p.theme.blockMarginLeftRight}
        ) !important;
        margin-left: ${(p) => p.theme.blockMarginLeftRight};
        margin-right: ${(p) => p.theme.blockMarginLeftRight};
      }
    }

    &.logo-center {
      max-width: 250px;
      margin: 0 auto;
    }

    &.logo-small {
      max-width: 300px;
      margin: 0;
    }

    &.profile-img {
      margin: 1em 0 !important;
      text-align: center;

      figcaption {
        display: none;
      }
      img {
        width: 90px !important;
        height: 90px !important;
        @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
          width: 60px !important;
          height: 60px !important;
        }
        object-fit: cover;
        border-radius: 50%;
        transition: 0.3s ease;
        filter: grayscale(1);
        &:hover {
          filter: none;
        }
      }
    }

    &.image-portrait {
      img {
        padding: 0 190px;
      }
      figcaption {
        // padding: 0 0 0 190px;
        text-align: center;
      }
      @media screen and (max-width: ${(p) => p.theme.breakpointTablet}) {
        img {
          padding: 0 150px;
        }
      }
      @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
        img {
          padding: 0 60px;
        }
        figcaption {
          padding: 0 ${(p) => p.theme.sidePaddingMobile};
        }
      }
    }

    &.portrait-center {
      max-width: 300px !important;
      margin-left: auto !important;
      margin-right: auto !important;
    }

    &.img-avatar {
      width: 75px;
      height: 75px;
      margin: 0;
    }

    img {
      width: 100% !important;
      height: auto !important;
    }
  }

  figure,
  .embed-wrapper {
    figcaption {
      ${figcaptionStyles}
    }
  }

  .profile-container {
    > div {
      display: flex;
      .profile-container-inner {
        width: 100%;
      }
    }
  }

  .wp-block-audio {
    margin: 0 0 1em 0;
    /*@media screen and (max-width: $breakpoint-mobile) {
    margin-inline-start: 20px;
    margin-inline-end: 20px;
  }*/
    audio {
      width: 100%;
    }
    figcaption {
      text-align: center;
      font-size: ${(p) => p.theme.fontSizeSuperSmall};
      margin-top: 0.5em;
      color: rgb(180, 180, 180);
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }
    @media print {
      display: none;
    }
  }
  .author-profile {
    background-color: transparent !important;
    font-size: ${(p) => p.theme.fontSizeSmall};
    margin-bottom: 4em;

    figure {
      margin-bottom: 0;
      img {
        width: 90px;
        border-radius: 50%;
        transition: 0.3s ease;
        filter: grayscale(1);
        &:hover {
          filter: none;
        }
      }
    }
  }

  & > video {
    width: 100%;
  }

  hr.wp-block-separator {
    border: 0;
    margin-top: 3em !important;
    margin-bottom: 3em !important;
    padding: 0 10rem;
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      padding: 0 3rem;
    }
    &:not(.is-style-dots) {
      height: 1px;
      &:after {
        content: "";
        display: block;
        height: 1px;
        width: 100%;
        background-color: rgba(150, 150, 150, 1);
      }
    }
    &.is-style-dots {
      &:after {
        content: "* * *";
        display: block;
        color: inherit;
        text-align: center;
        letter-spacing: 0.25em;
      }
    }
  }
`
