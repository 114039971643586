import React, { useContext } from "react"
import styled from "styled-components"
import goaLogoImg from "./goa-logo-2021.png"
import { LangContext } from "gatsby-source-dek-wp"

const GoaWrapper = styled.div`
  height: 50px;
  margin: 0 auto 1rem;
  padding: 0.4em;
  display: flex;
  align-items: center;
  justify-content: center;
`

const GoaLogoLink = styled.a`
  color: white !important;
  text-decoration: none !important;
  height: 100%;
`

const GoaImg = styled.img`
  height: 100%;
  margin-right: 16px;
  vertical-align: middle;
  a:hover & {
    filter: brightness(.9);
  }
`

const GoaLabel = styled.span`
  font-size: 0.5em;
  line-height: 1.5em;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  color: white;
`

interface TranslationObj {
  [key: string]: string
}

const labels: TranslationObj = {
  de: "Preisträger 2021",
  ru: "лауреат 2021 года",
  en: "awarded in 2021",
  uk: "лауреат 2021 року",
  be: "лаўрэат 2021 года"
}

const GoaLogo = () => {
  const lang = useContext(LangContext)
  return (
  <GoaWrapper>
    <GoaLogoLink
      href="https://www.grimme-online-award.de/2021/preistraeger/p/d/dekoder-specials-1/"
      target="_blank"
      rel="noreferrer"
    >
      <GoaImg src={goaLogoImg} alt="Grimme Online Award" />
      <GoaLabel>{labels[lang.id]}</GoaLabel>
    </GoaLogoLink>
  </GoaWrapper>
)}

export default GoaLogo
