import styled from "styled-components"

export const MetaInfoWrapper = styled.div<{ref: React.RefObject<HTMLDivElement>}>`
  &,
  button {
    color: #777;
    font-weight: 300;
    text-transform: uppercase;
  }
  font-size: ${(p) => p.theme.fontSizeSuperSmall};
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
  line-height: 1.5;

  .meta-item {
    a,
    a:hover,
    a:active,
    a:visited {
      color: #777 !important;
      text-decoration: none !important;
      border-bottom: 1px solid #777 !important;
    }
  }

  .meta-item.person {
    > span:not(:last-child)::after {
      content: ",";
      margin: 0 0.3em 0 0;
    }
  }

  &.no-items {
    display: none;
  }

  .clickable {
    cursor: pointer;
  }

  .meta-items {
    > span:not(:last-child)::after {
      content: "|";
      margin: 0 0.5em;
    }
  }

  .author-image {
    width: 75px;
    height: 75px;
    overflow: hidden;
    flex-shrink: 0;
    margin-right: 1em;
    border: 2px solid transparent;
    border-radius: 50%;
    padding: 2px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      filter: grayscale(1);
      object-fit: cover;
    }
    &.active,
    &:hover {
      border-color: ${(p) => p.theme.colors?.current || p.theme.titleColorSolid};
    }
    &:hover,
    &.hover {
      border-color: ${(p) => p.theme.colors?.current || p.theme.titleColorSolid};
      img {
        filter: none;
      }
    }
  }
  .author-image:not(:first-child) {
    margin-left: -10px;
  }
`
