import React, { useState, useEffect } from "react"
import styled from "styled-components"

interface WrapperProps {
  show?: boolean
  empty?: boolean
}

const CaptionsWrapper = styled.div<WrapperProps>`
  position: absolute;
  bottom: 50px;
  z-index: 9;
  color: white;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: ${p => p.theme.fontSizeH5};
  padding: 0.3em 0.5em;
  font-weight: 700;
  background-color: rgba(0, 0, 0, 0.4);
  max-width: 70%;

  transition: opacity 1s ease-in-out;
  opacity: ${(p) => (p.show ? 1 : 0)};

  pointer-events: none;

  display: ${(p) => (p.empty ? "none" : "block")};

  @media screen and (max-width: ${p => p.theme.breakpointTablet}) {
    font-size: 1em;
  }

  @media screen and (max-width: ${p => p.theme.breakpointMobile}) {
    font-size: ${p => p.theme.fontSizeSmall};
    max-width: calc(100% - 30px);
  }
`

interface Props {
  videoRef: React.MutableRefObject<HTMLVideoElement | null>
  show?: boolean
}

const Captions = ({ videoRef, show }: Props) => {
  const currentCaption = useCaptions(videoRef)
  return (
    <CaptionsWrapper show={show} empty={!currentCaption}>
      {currentCaption}
    </CaptionsWrapper>
  )
}

export default Captions

function useCaptions(videoRef) {
  const [currentCaption, setCurrentCaption] = useState("")

  useEffect(() => {
    const v = videoRef.current
    if (!v || !v.textTracks.length) return
    v.textTracks[0].mode = "hidden"
    v.textTracks[0].addEventListener("cuechange", onCueChange)
    return () => {
      if (!v || !v.textTracks.length) return
      v.textTracks[0].removeEventListener("cuechange", onCueChange)
    }

    function onCueChange(e) {
      if (!e.target.activeCues.length) setCurrentCaption("")
      else {
        const text = e.target.activeCues[0].text
        setCurrentCaption(text)
      }
    }
  }, [videoRef])

  return currentCaption
}
