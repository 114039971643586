import styled from "styled-components"

export const QuizWrapper = styled.div`
  padding-bottom: 5em;
`

export const NextButton = styled.button`
  display: block;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.33);
  padding: 1em 1em;
  width: 200px;
  margin: 2em auto;
  background-color: ${(p) =>
    !p.inactive ? p.theme.titleColorSolid : "rgba(0,0,0,0.33)"};
  pointer-events: ${(p) => (!p.inactive ? "all" : "none")};
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: ${p => p.theme.fontSizeSmall};
  &:hover {
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.33);
  }
`
