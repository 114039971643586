import React, {
  useState,
  useRef,
  useContext,
  createContext,
  useMemo,
  Dispatch,
  SetStateAction,
} from "react"
import { MapDiv, MapWrapper, OverlayWrapper } from "./map.style"
import { MapPointContext } from "./map-points"
import { useBodyClass } from "../../modules/hooks"
import useMapbox from "./mapbox"
import useFlyTo from "./fly-to"
import { useMarker } from "./marker"
import useMapLock from "./map-lock"
import { withMapPoints } from "./map-points"
import { LockContext } from "./lock-context"
import MapPoint from "./map-point"
import { withLayerManager } from "./layer-manager"
import { FillLayerBtnWrapper } from "./fill-layer.style"
import { ScrollTrigger } from "../scroll-trigger/scroll-trigger"
import { CameraDebug } from "./camera-debug"
import { Popup } from "./popup/popup"
import chroma from "chroma-js"
import { VisContainer, VisRefContext } from "../scroll-trigger/vis-container"
import { useIntersectionObserver } from "gatsby-source-dek-wp"
import { Map } from "mapbox-gl"
import { Feature } from "@turf/helpers"

export const MapContext = createContext<Map | undefined>(undefined)
export const SelectedContext = createContext<
  | [Feature | undefined, Dispatch<SetStateAction<Feature | undefined>>]
  | undefined
>(undefined)

interface MapProps {
  children: React.ReactNode[]
  options: {
    center?: [number, number]
    zoom?: number
    bearing?: number
    pitch?: number
    projection?: string
    style?: string
    splitView?: boolean
  }
  fillLayers?: React.ReactNode[]
}

const MapboxMap = ({ children, options, fillLayers }: MapProps) => {
  const ref: React.MutableRefObject<HTMLDivElement | null> = useRef(null)
  const [{ mapPoints, current }] = useContext(MapPointContext)
  // const firstPoint = mapPoints.length ? mapPoints[0] : undefined
  const hasScrollElements = children.some(
    (c) => [MapPoint, ScrollTrigger].includes(c.type) && !c?.props?.hidden,
  )
  const hasMapPoints = children.some(
    (c) => [MapPoint].includes(c.type) && !c?.props?.hidden,
  )

  const SCROLL_OFFSET_TOP = 0
  const [inViewRef, inView] = useIntersectionObserver({
    threshold: 0,
    rootMargin: `-${SCROLL_OFFSET_TOP}% 0% -${100 - SCROLL_OFFSET_TOP}% 0%`,
  })
  useBodyClass(inView ? "on-map" : "")

  const map = useMapbox(ref, options, hasScrollElements)
  const [isLocked, setIsLocked, lockComp] = useMapLock(map, inView)
  useMarker(mapPoints, current, map)
  useFlyTo(current, map, hasMapPoints)
  useBodyClass("with-map")

  const selectedState = useState<Feature | undefined>()
  const [selected, setSelected] = selectedState
  const selectedColor = getSelectedColor(selected)

  const primaryKey = "PipelineName"

  // TODO: DRY / scroll trigger
  const box: React.MutableRefObject<HTMLDivElement | null> = useRef(null)
  const container: React.MutableRefObject<HTMLDivElement | null> = useRef(null)
  const refs = useMemo(
    () => ({ box, container }),
    [box?.current, container?.current],
  )

  return (
    <LockContext.Provider value={{ isLocked, setIsLocked }}>
      <VisRefContext.Provider value={refs}>
        <MapContext.Provider value={map}>
          <SelectedContext.Provider value={selectedState}>
            <MapWrapper
              ref={inViewRef}
              // style={{ border: inView ? "1px solid green" : "1px solid yellow" }}
              className={`${isLocked ? "map-locked" : "map-not-locked"} ${
                hasScrollElements && "has-scroll-elements"
              } ${options.splitView ? "split-view" : ""}`}
            >
              <MapDiv
                ref={ref}
                isLocked={isLocked}
                className={`${options.splitView ? "split-view" : ""}`}
              >
                {hasScrollElements && lockComp}
                {!isLocked && <CameraDebug />}
                <VisContainer type="container" ref={container} />
                <VisContainer type="box" ref={box} />
                {!!selected && (
                  <Popup
                    title={selected?.properties?.[primaryKey]}
                    close={() => setSelected(undefined)}
                    color={selectedColor}
                  >
                    <table>
                      <tbody>
                        {Object.keys(selected?.properties || {})
                          .filter((key) => !!selected?.properties?.[key])
                          .map((key) => (
                            <tr key={key}>
                              <td>{key}</td>
                              <td>
                                {parseLink(selected?.properties?.[key] || "")}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </Popup>
                )}
              </MapDiv>
              <OverlayWrapper>
                {!!fillLayers?.length && (
                  <FillLayerBtnWrapper>{fillLayers}</FillLayerBtnWrapper>
                )}
                {children}
              </OverlayWrapper>
            </MapWrapper>
          </SelectedContext.Provider>
        </MapContext.Provider>
      </VisRefContext.Provider>
    </LockContext.Provider>
  )
}

export default withLayerManager(withMapPoints(MapboxMap))

function getSelectedColor(selected) {
  if (!selected) return
  const { r, g, b } = selected.layer.paint["line-color"]
  console.log({ r, g, b })
  return chroma(
    Math.round(r * 255),
    Math.round(g * 255),
    Math.round(b * 255),
  ).hex()
}

function parseLink(text = "") {
  if (typeof text !== "string") return text
  const url = text.match(/(http(s?):\/\/(.*))/)?.[0]
  return url ? (
    <a href={url} target="_blank" rel="noreferrer">
      {text}
    </a>
  ) : (
    text
  )
}

// import useRoutes from "./routes"
// import useDistance from "./distance"
// const [routePoints, routeSelector] = useRoutes(mapPoints, map, mapLoaded)
// const [odometer, km] = useDistance(current, routePoints)
