import { css } from "styled-components"

export const translateJournalismStyles = css`
  h4 {
    font-family: "Courier", "Courier New";
    text-transform: uppercase;
    font-size: ${(p) => p.theme.fontSizeH5};
    @media screen and (min-width: ${(p) => p.theme.breakpointTablet}) {
      transform: translateX(-2em);
    }
  }
  .intro-wrapper p {
    font-family: "Courier", "Courier New";
    font-style: normal;
    // font-weight: 700;
  }
  blockquote p {
    font-family: "Courier", "Courier New";
    font-style: normal;
  }
  &.social-image-body {
    h1, h2 {
      font-family: "Courier", "Courier Fallback", "Courier New";
      text-transform: none;
    }
    h1 {
      margin: 0 !important;
      font-size: 2.441em !important; // fontSizeH2
    }
    h2 {
      margin: 0 !important;
      padding-top: 1rem;
      font-weight: 400;
      font-size: 1.563em !important; // fontSizeH4
    }
  }
`
