import React from "react"
import useDragMove from "./drag-move"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { Body, PopupWrapper, Title } from "./popup.style"

interface PopupProps {
  title: string
  children?: any
  icon?: JSX.Element
  color?: string
  active?: boolean
  goPrev?: (_: any) => void,
  goNext?: (_: any) => void,
  close: (_: any) => void
}

export function Popup({
  title = "",
  children,
  icon,
  color,
  active = true,
  goPrev,
  goNext,
  close
}: PopupProps) {
  const [bind, style] = useDragMove()
  if (!active) return null
  return (
    <PopupWrapper style={style}>
      <Title color={color} {...bind()}>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: "1em" }}>{icon}</div>
          <div>{title}</div>
        </div>
        <button onClick={close}>
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </Title>
      <Body>{children}</Body>
    </PopupWrapper>
  )
}

