import { css } from "styled-components"
import { TitleInner } from "../../cover/cover.style"
import { freedomProtestStyles } from "./freedom-protest"

export const kurapatyStyles = css`
  ${freedomProtestStyles}
  ${TitleInner} {
    justify-content: flex-start;
    align-items: flex-start;
    h1 {
      font-size: 6rem !important;
      @media screen and (max-width: ${p => p.theme.breakpointMobile}) {
        font-size: 4rem !important;
      }
    }
  }
`