import { useState, useEffect, useContext } from "react"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import { getOffset } from "./fly-to"
import { AuthContext } from "gatsby-source-dek-wp"

declare global {
  interface Window {
    map: any
  }
}

interface MapOptions {
  center?: [number, number]
  projection?: string
  style?: string
  zoom?: number
}

export default function useMapbox(
  elementRef: React.MutableRefObject<HTMLDivElement | null>,
  options: MapOptions,
  hasOffset: boolean,
) {
  const isAuthed = useContext(AuthContext)
  const [map, setMap] = useState<mapboxgl.Map | undefined>(undefined)
  const [mapLoaded, setMapLoaded] = useState(false)
  const setMapLoadedTrue = () => setMapLoaded(true)
  useEffect(() => {
    // if (!center) return
    // center = undefined
    const mapObj = mapEmbedder(elementRef.current, options, hasOffset)
    if (!mapObj) return
    const wait = () => {
      if (!mapObj.isStyleLoaded()) setTimeout(wait, 200)
      else setMapLoadedTrue()
    }

    if (isAuthed) {
      window.map = mapObj
    }

    mapObj.on("load", wait)
    setMap(mapObj)
    return () => {
      setMapLoaded(false)
      mapObj.off("load", wait)
    }
  }, [elementRef, options, hasOffset, isAuthed])
  return mapLoaded ? map : undefined
}

function mapEmbedder(element: HTMLDivElement | undefined, options: MapOptions, hasOffset = true) {
  if (!element) return
  if (typeof mapboxgl === "undefined") return

  const {
    center = [13.408333, 52.518611],
    projection = "mercator",
    style = "mapbox://styles/mapbox/light-v10",
    zoom = 3,
  } = options

  mapboxgl.accessToken = process.env.GATSBY_MAPBOX_KEY || ""

  const map = new mapboxgl.Map({
    container: element,
    style,
    center,
    zoom,
    minZoom: 1,
    maxZoom: 16,
    projection,
  })

  if (hasOffset) {
    map.flyTo({
      center,
      offset: getOffset() as mapboxgl.PointLike,
    })
  }

  // map.scrollZoom.disable();
  map.addControl(
    new mapboxgl.NavigationControl({ showZoom: true }),
    "bottom-left",
  )
  // map.addControl(new mapboxgl.FullscreenControl());
  /* var language = new MapboxLanguage({defaultLanguage: langId});
  map.addControl(language);*/

  return map
}
