import React from "react"
import { Script } from "gatsby"
import { Transformer } from "gatsby-source-dek-wp"

export const scriptTransformer: Transformer = (node) => {
  if ("name" in node && "attribs" in node && node.name === "script" && node.attribs.src ) {
    return (
      <Script {...node.attribs} />
    )
  }
}
