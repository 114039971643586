import { css } from "styled-components"

const mediaTextAsAvatar = css`
  .wp-block-media-text {
    min-height: auto !important;
    .wp-block-media-text__media img {
      width: 150px;
      height: 150px;
      &.size-thumbnail {
        border-radius: 50%;
        object-fit: cover;
      }
    }
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      display: flex;
      flex-direction: row-reverse !important;
      justify-content: flex-end;
      .wp-block-media-text__media {
        flex-shrink: 0.9;
        img {
          width: 120px;
          height: 120px;
        }
      }
      .wp-block-media-text__content {
        flex-shrink: 0.4;
        width: 100%;
        // padding: 0;
      }
    }
  }
`

export const diarySpecialStyles = css`
  ${mediaTextAsAvatar}

  .wp-block-media-text__content p {
    font-family: "ReenieBeanie";
    font-size: ${(p) => p.theme.fontSizeH3};
  }

  p.has-small-font-size {
    border-left: 3px solid ${(p) => p.theme.articleRed};
    padding-left: 1em;
  }
  h1,
  h2 {
    font-family: "ReenieBeanie";
    max-width: 1000px !important;
    // text-transform: uppercase;
  }
  h1 {
    margin-top: 1em !important;
    margin-bottom: .5em;
    font-size: ${(p) => p.theme.fontSizeH0};
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      font-size: ${(p) => p.theme.fontSizeH2};
    }
  }
  h2 {
    margin-top: 0;
    font-size: ${(p) => p.theme.fontSizeH1};
  }
  hr::after {
    font-family: "ReenieBeanie";
    font-size: ${(p) => p.theme.fontSizeH2};
  }
  blockquote p {
    font-family: "ReenieBeanie";
    font-size: ${(p) => p.theme.fontSizeH3};
  }
  .wp-block-gallery {
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      flex-direction: column;
      justify-content: center;
    }
  }
  &.social-image-body h1 {
    font-size: 5em !important;
    margin: 0 !important;
  }

  .carousel-wrapper {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    margin-top: 0em !important;
    margin-bottom: 0em !important;
    .carousel-arrow {
      // display: none;
      transform: translateY(calc(-50% + 2.5rem));
    }
    figure {
      img {
        box-shadow: inset 0 0 50px rgba(0, 0, 0, 1),
          5px 5px 20px 0px rgba(0, 0, 0, 0.6); // 20p
      }
      figcaption {
        margin-top: 1em;
      }
    }
  }
`
