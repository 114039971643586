import styled, { css } from "styled-components"
import { boxStyles } from "./map-point.style"

export const MapWrapper = styled.div`
  position: relative;
  margin: 2rem 0 2rem 0 !important;
  padding: 0 !important;
  width: 100% !important;
  max-width: none !important;
  height: 100vh;

  &.has-scroll-elements {
    height: auto;
  }

  & + .figcaption {
    margin-top: -1rem;
    margin-bottom: 2rem;
  }
`

interface MapDivProps {
  ref: React.RefObject<HTMLDivElement | null> | undefined
  isLocked: boolean
}

export const MapDiv = styled.div<MapDivProps>`
  overflow: hidden;
  z-index: 9;
  // pointer-events: none;

  position: sticky !important;
  top: 0;

  font-size: 1rem !important;
  font-family: "Roboto Condensed" !important;

  .mapboxgl-ctrl-attrib {
    font-size: ${(p) => p.theme.fontSizeSuperSmall} !important;
    a,
    a:hover {
      color: black !important;
      text-decoration: none !important;
    }
  }

  .dark-mode & {
    .mapboxgl-ctrl-group {
      background: ${(p) => p.theme.bgDark};
      box-shadow: 2px 2px 10px rgba(255, 255, 255, 0.1);
      button span {
        filter: invert(100%);
      }
    }
    .mapboxgl-ctrl-attrib {
      a {
        color: white !important;
      }
      background: transparent;
    }

    .mapboxgl-popup {
      .mapboxgl-popup-content {
        background: ${(p) => p.theme.bgDark};
      }
      &.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
        border-top-color: ${(p) => p.theme.bgDark};
      }
      &.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
        border-bottom-color: ${(p) => p.theme.bgDark};
      }
      &.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
        border-right-color: ${(p) => p.theme.bgDark};
      }
      &.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
        border-left-color: ${(p) => p.theme.bgDark};
      }
    }
  }

  .map-locked & {
    .mapboxgl-canvas-container {
      cursor: default;
    }
  }

  width: 100% !important;
  height: 100vh !important;
  padding: 0 !important;
  margin: 0 !important;
  max-width: none !important;

  &.split-view {
    @media screen and (min-width: ${(p) => p.theme.breakpointMobile}) {
      // left: 50%;
      // width: 50% !important;
      .mapboxgl-ctrl-group {
        display: none;
      }
    }
  }
`

export const figureStyles = css`
  min-height: 90vh;
  padding-bottom: 90vh;
  img {
    ${boxStyles}
  }
`

export const OverlayWrapper = styled.div`
  z-index: 10;
  position: relative;
  pointer-events: none;
  margin-top: -100vh; // calc(-100vh + 2em);

  transition: transform 0.3s ease-in-out;

  button {
    pointer-events: auto;
  }

  .map-not-locked & { // :not(.split-view)
    transform: translateX(-50vw); // -50
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      transform: translateX(-95vw);
    }
  }

  > figure {
    margin-bottom: 90vh !important;
    padding: 1em !important;
    background-color: white;
    ${boxStyles}
  }

  .profile-container {
    min-height: 90vh;
    padding-bottom: 90vh;
    > .wp-block-group__inner-container {
      ${boxStyles}
      padding-right: 1.5em;
      h5,
      .profile-img {
        margin-top: 0;
      }
      h5 {
        font-size: ${(p) => p.theme.fontSizeH4};
        margin-bottom: 1em;
      }
      p {
        margin: 0;
      }
    }
  }
`
