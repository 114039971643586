import styled from "styled-components"
import { animated } from "@react-spring/web"

export const CardBack = styled.div`
  background-color: rgb(235, 235, 235);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateZ(-1px) rotateY(180deg) !important;
  backface-visibility: hidden;
  padding: 2rem;
  display: flex;
  align-items: center;
  p {
    display: block;
    color: black;
    font-size: ${(p) => p.theme.fontSizeSmall};
  }
`

export const CardDiv = styled(animated.div)`
  will-change: transform;
  pointer-events: auto;
  // border: 1px dashed orange;
  @media screen and (min-width: ${(p) => p.theme.breakpointMobile}) {
    touch-action: none;
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
  transform-style: preserve-3d;
  /*.category-filter-active & {
    transform: none !important;
  }*/

  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2);

  > a {
    touch-action: none;
    user-drag: none;
    user-select: none;
  }
  > a > div {
    margin: 0 !important;
    transform-style: preserve-3d;
    pointer-events: none;
  }

  .template-ria & {
    border-radius: 20px;
    > a > div, ${CardBack} {
      border-radius: 20px;
    }
  }
`

