import React from "react"
import { YoutubeVideo } from "./youtube"

export function youtubeVideoTransformer(node, i, { classList, children }) {
  if (classList.includes("wp-block-embed-youtube")) {
    const childs = React.Children.toArray(children)
    const container = childs.find(c => c.type === "div")
    const figcaption = childs.find(c => c.type === "figcaption")
    const iframe = React.Children.toArray(container.props.children).find(c => c.type === "iframe")
    const iframeProps = iframe.props
    return <YoutubeVideo className={classList.join(" ")} {...iframeProps} figcaption={figcaption} />
  }
}
