import React, { useContext, useEffect, useMemo } from "react"
import mapboxgl from "mapbox-gl"
import { createRoot } from "react-dom/client"
import "./marker.scss"
import { MarkerPopup } from "./marker-popup"
import { LangContext } from "gatsby-source-dek-wp"

export function useMarker(points = [], current, map) {
  const langId = useContext(LangContext)?.id
  const markers = useMemo(() => {
    // create markers
    return (points || []).map((p) => {
      const placeholder = document.createElement("div")
      const popup = new mapboxgl.Popup().setDOMContent(placeholder)

      const onClick = () => {
        popup.remove()
        p.onClick()
      }

      const comp = (
        <MarkerPopup
          title={p.title}
          imgSrc={p.imgSrc}
          onClick={!p.hidden && onClick}
          color={p.color}
          langId={langId}
        />
      )
      const root = createRoot(placeholder)
      root.render(comp)
      const marker = new mapboxgl.Marker({
        color: p.color,
      })
        .setLngLat([p.lon, p.lat])
        .setPopup(popup)
      const el = marker.getElement()
      el.setAttribute("data-id", p.id)
      // el.setAttribute("style", `--marker-color: ${p.color}`)
      return marker
    })
  }, [points, langId])

  useEffect(() => {
    // add to map
    if (!map) return
    markers.forEach((m) => m.addTo(map))
    return () => {
      markers.forEach((m) => m.remove())
    }
  }, [markers, map])

  useEffect(() => {
    // toggle selected-class
    markers.forEach((m) => {
      const el = m.getElement()
      const isSelected = current?.id === el.getAttribute("data-id")
      if (isSelected) el.classList.add("selected")
      else el.classList.remove("selected")
    })
  }, [markers, current])
}
