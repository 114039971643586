import React, { Suspense } from "react"
import innerText from "react-innertext"
import { isElement, Transformer } from "gatsby-source-dek-wp"
import MapPoint from "./map-point"

const Map = React.lazy(() => import("./map"))
const FillLayer = React.lazy(() =>
  import("./fill-layer").then((module) => ({
    default: module.FillLayerWithBtn,
  })),
)
const LineString = React.lazy(() =>
  import("./line-string").then((module) => ({ default: module.LineString })),
)

export const mapTransformer: Transformer = (
  node,
  i,
  { children: _children, classList },
) => {
  if (
    "attribs" in node &&
    classList.includes("wp-block-dekoder-custom-blocks-map")
  ) {
    const children = React.Children.toArray(_children).filter(isElement)
    const {
      lat,
      lon,
      zoom,
      bearing,
      pitch,
      projection,
      styleurl: style,
    } = node.attribs
    const center = lon && lat ? [parseFloat(lon), parseFloat(lat)] : [53, 35]
    const fillLayers = children.filter((c) => c?.type === FillLayer)
    const otherChildren = children.filter((c) => c?.type !== FillLayer)

    const options = {
      center,
      zoom: parseFloat(zoom),
      bearing: parseFloat(bearing),
      pitch: parseFloat(pitch),
      projection,
      style,
      splitView: classList.includes("split-view"),
    }

    return (
      <Suspense fallback={<div>Loading ...</div>}>
        <Map {...{ options, fillLayers }}>{otherChildren}</Map>
      </Suspense>
    )
  }
}

export const mapPointTransformer: Transformer = (
  node,
  i,
  { classList, children },
) => {
  if (
    "attribs" in node &&
    classList.includes(
      "wp-block-dekoder-custom-blocks-container-with-coordinates",
    )
  ) {
    const { lat, lon, category, zoom, bearing, pitch } = node.attribs
    const props = {
      lat: parseFloat(lat),
      lon: parseFloat(lon),
      zoom: parseFloat(zoom),
      bearing: parseFloat(bearing),
      pitch: parseFloat(pitch),
      category,
      hidden: classList.includes("hide"),
    }
    return <MapPoint {...props}>{children}</MapPoint>
  }
}

export const mapFillLayerTransformer: Transformer = (
  node,
  i,
  { classList, children: _children },
) => {
  // maybe use only map-geojson blocks in future?
  if (
    "attribs" in node &&
    classList.includes("wp-block-dekoder-custom-blocks-map-fill-layer")
  ) {
    const children = React.Children.toArray(_children).filter(isElement)
    const pre = children[0]
    const geoJson = innerText(pre) || "{}"
    const {
      id,
      type = "fill",
      label = "(undefined)",
      color = "#ff0000",
    } = node.attribs

    const props = {
      id,
      identifier: id,
      label,
      geoJsonStr: geoJson,
      color,
      isActive: node.attribs?.active === "true",
    }
    // const isLine = geoJson.includes("LineString")

    return (
      <Suspense fallback={<div></div>}>
        {type === "line" ? (
          <LineString {...props} />
        ) : type === "fill" ? (
          <FillLayer {...props} />
        ) : (
          <div>"circle layers currently not supprted</div>
        )}
      </Suspense>
    )
  }
}

export const mapGeojsonTransformer: Transformer = (
  node,
  i,
  { classList, children: _children },
) => {
  if (
    "attribs" in node &&
    classList.includes("wp-block-dekoder-custom-blocks-map-geojson")
  ) {
    const children = React.Children.toArray(_children).filter(isElement)
    const {
      id,
      type = "fill",
      label = "(undefined)",
      color = "#ff0000",
    } = node.attribs

    const url =
      node.children.find(
        (c) => "name" in c && c?.name === "div" && "children" in c,
      )?.children?.[0]?.attribs?.href || ""

    const codeBlock = children.find((c) => c?.type === "pre")
    let paint
    if (codeBlock) {
      try {
        paint = JSON.parse(innerText(codeBlock))
      } catch (e) {
        console.error(e)
      }
    }

    const props = {
      id,
      identifier: id,
      label,
      // geoJsonStr: "{}",
      paint,
      url,
      color,
      isActive: node.attribs?.active === "true",
    }

    // todo: other types
    return (
      <Suspense fallback={<div></div>}>
        {type === "line" ? (
          <LineString {...props} />
        ) : type === "fill" ? (
          <FillLayer {...props} />
        ) : (
          <div>"circle layers currently not supprted</div>
        )}
      </Suspense>
    )
  }
}
