import React, { useMemo, useEffect, useContext } from "react"
import along from "@turf/along"
import length from "@turf/length"
import { MapPointContext } from "./map-points"
import { setRoutes } from "./map-point-reducer"
import RouteSelector from "./route-selector"

export const COLORS = ["#FA5D37", "#72D633", "#306DFF"]
// const COLORS = ["#C22460", "#7C3EC2", "#264575"]

function getArc(orig, dest) {
  // coordinate arrays [lon, lat]
  const lineGeojson = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [orig, dest],
        },
      },
    ],
  }
  const steps = 100
  const arr = Array.from(new Array(steps))
  const totalDist = length(lineGeojson.features[0], {
    units: "kilometers",
  })

  const arc = arr.reduce((acc, curr, i) => {
    const isLast = i === arr.length - 1
    const segDist = isLast ? totalDist : (totalDist / steps) * i
    const segment = along(lineGeojson.features[0], segDist, {
      units: "kilometers",
    })
    return [...acc, segment.geometry.coordinates]
  }, [])

  // console.log("GETTING ARC", orig, dest, arc)
  return arc
}

interface RoutePoint {
  lat: number
  lon: number
  category?: string
}

function useRoutes(points: RoutePoint[], map, mapLoaded) {
  const [, dispatch] = useContext(MapPointContext)
  const routes = useMemo(() => {
    if (!points.length) return []

    const routesObj = (points || [])
      .filter((p) => p.lat && p.lon)
      .reduce((acc, p) => {
        const categoryName = p.category || "default"
        const catPoints = acc[categoryName] || []
        const newCatPoints = [...catPoints, p]
        return { ...acc, [categoryName]: newCatPoints }
      }, {})

    const routes = Object.keys(routesObj).map((name, i) => {
      const firstPoint = routesObj[name][0]
      let imgUrl = ""
      try {
        const portraitContainer = firstPoint.children[0]
        imgUrl =
          portraitContainer.props.children[0].props.children[0].props
            .children[0].props.src
      } catch (e) {
        console.log(e)
      }
      return {
        name,
        points: routesObj[name],
        coordinates: routesObj[name].map((p) => [p.lon, p.lat]),
        color: COLORS[i % COLORS.length],
        imgUrl: imgUrl,
        onClick: firstPoint.onClick,
      }
    })
    return routes
  }, [points])

  useEffect(() => {
    dispatch(setRoutes(routes))
  }, [routes, dispatch])

  useEffect(() => {
    if (!map || !mapLoaded) return
    routes.forEach((route, i) => {
      const routeCoordinates = route.coordinates.reduce((acc, curr, i, arr) => {
        if (i === 0) return [curr]
        const prev = arr[i - 1]
        const arc = getArc(prev, curr)
        return [...acc, ...arc]
      }, [])

      let routeGeojson = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: routeCoordinates,
            },
          },
        ],
      }
      map.addSource(`route_${route.name}`, {
        type: "geojson",
        data: routeGeojson,
      })
      map.addLayer({
        id: `route_${route.name}`,
        source: `route_${route.name}`,
        type: "line",
        paint: {
          "line-width": 3,
          "line-color": route.color,
        },
      })
    })

    return () => {
      routes.forEach((route) => {
        map.removeSource(`route_${route.name}`)
      })
    }
  }, [map, mapLoaded, routes])

  const routePoints = useMemo(
    () =>
      routes.reduce((acc, route) => {
        const points = route.points.map((p) => ({
          ...p,
          color: route.color,
        }))
        return [...acc, ...points]
      }, []),
    [routes]
  )

  const routeSelector = <RouteSelector routes={routes} />

  return [routePoints, routeSelector, routes]
}

export default useRoutes
