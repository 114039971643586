import styled from "styled-components"

export const QuizItemWrapper = styled.div`
  margin-top: 3em;
  h4 {
    line-height: 1.5em;
    font-size: ${p => p.theme.fontSizeH5} !important;
    &.quiz-item-question {
      margin-top: 1em !important;
      margin-bottom: 1em !important;
    }
  }
`

export const QuizOptions = styled.div`
  margin-bottom: 2em;
`

export const QuizCounter = styled.div`
  font-size: small;
  letter-spacing: 3px;
  display: flex;
  width: 100%;
  white-space: nowrap;
  &::after {
    display: block;
    content: "";
    border-bottom: 1px solid black;
    width: 100%;
    margin-left: 1em;
  }
`
